body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.login-image {
  width: auto; /* 使用视窗宽度作为宽度 */
  height: 100vh; /* 使用视窗高度作为高度 */
  background-image: url('./img/zx_1.png');
  background-size: cover; /* 背景图片尽可能填充整个容器 */
  background-position: center; /* 将背景图片置中 */
  background-repeat: no-repeat; /* 禁止背景图片重复显示 */
}

.transparentModal .ant-modal-content {
  background: rgba(255, 255, 255, 0.2);
  /* box-shadow:0px -6px 20px 6px #1677ff96, 0px 4px 20px 6px #ffffff; */
  /* padding: 8% 10%; */
}

.ant-modal .ant-modal-header{
  background: rgba(0, 0, 0, 0);
  margin-bottom: 5%;
}
.footer-image {
  width: auto; /* 使用视窗宽度作为宽度 */
  height: 100vh; /* 使用视窗高度作为高度 */
  background-image: url('./img/wishingwell1.png');
  background-size: cover; /* 背景图片尽可能填充整个容器 */
  background-position: center; /* 将背景图片置中 */
  background-repeat: no-repeat; /* 禁止背景图片重复显示 */

}
.ant-modal .ant-modal-title{
  font-size: 200%;
}

.ant-input-affix-wrapper {
  background: rgba(0, 0, 0, 0);
}

.ant-input{
  background: rgba(0, 0, 0, 0);
}

.ant-modal .ant-modal-body{
  margin: 8% 10%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
